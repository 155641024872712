<template>
  <div>
    <div class="p-banner mb-40">
      <img alt="" class="p-banner-bg" src="../../assets/img/product/banner5.png">
      <div class="container" v-animate-on-intersect>
        <div class="p-banner-title">氢氰酸衍生物系列产品</div>
        <div class="p-banner-sub-title p-banner-sub-title-long">氢氰酸衍生物系列产品</div>
      </div>
    </div>
    <div class="container">
      <div class="product-item-title">在氢氰酸衍生物产品中，我们提供下列产品的工艺技术包服务</div>
      <el-row :gutter="$baseVal.isPc ? 50 : 25" class="flex-warp" justify="space-between" type="flex">
        <el-col :lg="8" :md="8" :sm="12" :xl="8" :xs="12">
          <div class="product-item download-item"  @click="downloadFile('/qqs/02-05-1-（S）-4-氯-3-羟基丁腈工艺技术介绍.pdf')">
            <img alt="" src="../../assets/img/product/qqsysw-1.png">
            <div class="product-item-name2">(S)-4-氯-3-羟基丁腈（A3）</div>
          </div>
        </el-col>
        <el-col :lg="8" :md="8" :sm="12" :xl="8" :xs="12">
          <div class="product-item download-item"  @click="downloadFile('/qqs/02-05-2-（S）-4-氯-3-羟基丁酸乙酯工艺技术介绍.pdf')">
            <img alt="" src="../../assets/img/product/qqsysw-2.png">
            <div class="product-item-name2">(S)-4-氯-3-羟基丁酸乙酯（A4）</div>
          </div>
        </el-col>
        <el-col :lg="8" :md="8" :sm="12" :xl="8" :xs="12">
          <div class="product-item download-item"  @click="downloadFile('/qqs/02-05-3-（R）-4-氰基-3-羟基丁酸乙酯工艺技术介绍.pdf')">
            <img alt="" src="../../assets/img/product/qqsysw-3.png">
            <div class="product-item-name2">(R)-4-氰基-3-羟基丁酸乙酯（A5）</div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  // name: "氢氰酸衍生物系列产品",
  methods:{
    downloadFile(url){
      window.open('/downloadFile' + url);
    }
  }
}
</script>

<style>
@import "../../assets/css/product.css";
</style>
